// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-identity-email-change-tsx": () => import("./../src/pages/identity/email-change.tsx" /* webpackChunkName: "component---src-pages-identity-email-change-tsx" */),
  "component---src-pages-identity-password-recovery-tsx": () => import("./../src/pages/identity/password-recovery.tsx" /* webpackChunkName: "component---src-pages-identity-password-recovery-tsx" */),
  "component---src-pages-identity-signup-confirmation-tsx": () => import("./../src/pages/identity/signup-confirmation.tsx" /* webpackChunkName: "component---src-pages-identity-signup-confirmation-tsx" */),
  "component---src-pages-identity-signup-invitation-tsx": () => import("./../src/pages/identity/signup-invitation.tsx" /* webpackChunkName: "component---src-pages-identity-signup-invitation-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-company-tsx": () => import("./../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-index-tsx": () => import("./../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-product-tsx": () => import("./../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-shipping-receiving-tsx": () => import("./../src/templates/shipping-receiving.tsx" /* webpackChunkName: "component---src-templates-shipping-receiving-tsx" */),
  "component---src-templates-soybeans-tsx": () => import("./../src/templates/soybeans.tsx" /* webpackChunkName: "component---src-templates-soybeans-tsx" */),
  "component---src-templates-terms-tsx": () => import("./../src/templates/terms.tsx" /* webpackChunkName: "component---src-templates-terms-tsx" */)
}

